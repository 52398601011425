<template>
  <div class="privacy">
    <div class="h4">
      个人信息隐私保护
    </div>
    <div class="protect">
      <div class="h5">
        一、 总则
      </div>
      <div class="wel">
        1、根据《中华人民共和国网络安全法》及相关法律法规要求，特制定本协议；
      </div>
      <div class="wel">
        2、“本协议”是指深圳蓝生脑科医院互联网医院医疗患者移动服务软件用户服务协议以及该协议项下所包含的其他附属协议、附加条款或条约。本协议中的软件指深圳蓝生脑科医院互联网医院医疗患者移动服务软件(以下简称“软件”）。本协议由软件的使用者（以下简称“您”）与软件共同签订。
      </div>
      <div class="wel">
        3、软件为您提供全面的医疗健康服务，为充分保护您对于软件提供的各项服务的知情权，软件就其提供的各项服务的相关性、有效性以及限制性提供以下条款。在此特别提醒您，在您使用绑卡和建卡前请确认已仔细阅读并充分理解了本协议，如果您对本协议的任何条款或者将来随时可能修改、补充的条款有异议，您可选择不成为本软件的用户。
      </div>
      <div class="wel">
        4、您在进行绑卡或建卡程序过程中，当您点选“确定”按钮时即视为您已仔细阅读本协议，同意接受本协议项下的所有条款，包括接受本软件对本协议条款随时所做的任何修改，并愿意受其约束。这之后方能按系统提示完成全部绑卡或建卡程序、享受挂号服务、报告查询等医疗健康信息服务。
      </div>
      <div class="wel">
        5、您在软件挂号、缴费、查看报告以及使用其他健康服务，即代表您已经同意本协议。
      </div>
      <div class="wel">
        5、本协议的条款适用于软件提供的各种服务，但当您使用软件某一特定服务时，如该服务另有单独的服务条款、指引或规则，您应遵守本协议及软件随时公布的与该服务相关的服务条款、指引或规则等。前述所有的指引和规则，均构成本协议的一部分。除非本协议另有其他明示规定，新推出的产品或服务、增加或强化目前本服务的任何新功能，均受到本协议之规范。
      </div>
      <div class="line" />
      <div class="h5">
        二、 服务简介
      </div>
      <div class="wel">
        1、您保证：提供详尽、真实、准确和完整的个人资料。如果资料发生变动，您应及时更改。若您提供任何错误、不实、过时或不完整的资料，并为本软件所确知；或者本软件有合理理由怀疑前述资料为错误、不实、过时或不完整的资料，本软件有权暂停或终止您的帐号，并拒绝您在现在或将来使用本服务的全部或一部分。在此情况下您可通过软件的申诉途径与软件取得联系并修正个人资料经软件核实后恢复账号使用。
      </div>
      <div class="wel">
        2、您在使用软件服务时，必须遵守中华人民共和国相关法律法规，您应同意将不会利用该服务进行任何违法、不当或有悖公序良俗的行为/活动，包括但不限于下列行为：
      </div>
      <div class="wel">
        （1）上载、展示、张贴、传播或以其它方式传送含有下列内容之一的信息：1）反对宪法所确定的基本原则的；2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；3）损害国家荣誉和利益的；4）煽动民族仇恨、民族歧视、破坏民族团结的；5）破坏国家宗教政策，宣扬邪教和封建迷信的；6）散布谣言，扰乱社会秩序，破坏社会稳定的；7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；8）侮辱或者诽谤他人，侵害他人合法权利的；9） 含有虚假、有害、胁迫侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感的内容；10）含有中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的。
      </div>
      <div class="wel">
        （2）为任何非法目的而使用网络服务系统。
      </div>
      <div class="wel">
        3、您不得利用软件服务从事以下活动：
      </div>
      <div class="wel">
        （1）未经允许，进入计算机信息网络或者使用计算机信息网络资源的；未经允许，对计算机信息网络功能进行删除、修改或者增加的；未经允许，对进入计算机信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增加的；故意制作、 传播计算机病毒等破坏性程序的；其他危害计算机信息网络安全的行为。
      </div>
      <div class="wel">
        （2）对软件上的任何数据作商业性利用，包括但不限于在未经软件运营方事先书面同意的情况下，以复制、传播等任何方式使用软件上展示的资料。
      </div>
      <div class="wel">
        （3） 使用任何装置、软件或例行程序等其他方式千预或试图干预软件的正常运作或正在软件上进行的任何交易、活动，或采取任何将导致不合理的庞大数据负载加诸软件网络设备的行动。
      </div>
      <div class="wel">
        （4）违反诚实信用原则的不正当竞争行为，或恶意下订单或虚假交易等其他恶意扰乱软件交易秩序的行为。
      </div>
      <div class="line" />
      <div class="h5">
        三、 您的帐号和安全性
      </div>
      <div class="wel">
        1、您一旦绑卡或建卡成功，成为软件的合法用户，您都要对任何以您帐号进行的所有活动和事件负全责，且您有权随时根据指示更改您的绑定状态。若发现任何非法使用您的帐号或存在安全漏洞的情况，请立即通知软件运营方。因黑客行为或您的保管疏忽等情况导致帐号遭他人非法使用，软件不承担责任。
      </div>
      <div class="wel">
        2、软件为向您提供软件服务，您同意并授权软件将您在绑卡和建卡、使用软件服务过程中提供、形成的信息传递给向您提供服务的软件及关联公司或其他组织，或从提供其他服务的软件关联公司或其他组织获取您在注册、使用服务期问提供、形成的信息。软件将根据中华人民共和国法律法规的要求，履行其作为互联网信息服务提供者应当履行的义务。
      </div>
      <div class="line" />
      <div class="h5">
        四、 隐私保护
      </div>
      <div class="wel">
        1、保护您隐私是软件的一项基本政策，软件保证不对外公开或向第三方提供您的注册资料及您在使用网络服务时存储在软件的非公开内容，但下列情况除外：
      </div>
      <div class="wel">
        （1）事先获得您的明确授权；
      </div>
      <div class="wel">
        （2）根据有关的法律法规要求；
      </div>
      <div class="wel">
        （3）按照相关政府主管部门的要求；
      </div>
      <div class="wel">
        （4）为维护社会公众的利益；
      </div>
      <div class="wel">
        （5）为维护软件的合法权益。
      </div>
      <div class="wel">
        2、信息收集
      </div>
      <div class="wel">
        在您使用软件服务的过程中，软件会按照如下方式收集您在使用服务时主动提供或因为使用服务而产生的信息，用以向您提供服务优化我们的服务以及保障您的帐号安全：
      </div>
      <div class="wel">
        （1） 当您注册软件服务时，我们可能会收集您的昵称、头像、姓名、手机号码、身份证号、银行卡号码、生物信息（例如指纹、面部图像、声纹信息）、疾病史等其他所需要的个人信息、财产信息。收集这些信息是为了帮助您完成软件注册，保护您软件帐号的安全。姓名、手机号码、身份证号码属于敏感信息，收集此类信息是为了满足相关法律法规的网络实名制要求。若您不提供这类信息您可能无法正常使用我们的服务。您还可以根据自身需求选择填写性别、地区等信息。此外，您可以选择向我们提供您的生物信息，该信息属于敏感信息，拒绝提供该信息仅会使您无法使用需要提供生物信息方能使用的功能，但不影响您正常使用软件的其他功能。
      </div>
      <div class="wel">
        （2）当您使用软件服务时，为保障您正常使用我们的服务，维护我们服务的正常运行，改进及优化我们的服务体验以及保障您的帐号安全，我们会收集您的设备型号、操作系统、唯一设备标识符（指由设备制造商编入到设备中的一串字符，可用于以独有方式标识相应设备）、登陆P地址、软件软件版本号、接入网络的方式、类型和状态、网络质量数据、设备加速器（如重力感应设备）、操作日志、服务日志信息（如您在软件进行搜索、查看操作的记录、服务故障信息、引荐网址等信息）等日志信息，这类信息是为提供服务必须收集的基础信息，如您拒绝提供的，则您可能无法正常使用软件服务。
      </div>
      <div class="wel">
        3、信息存储
      </div>
      <div class="wel">
        （1）信息存储的地点
      </div>
      <div class="wel">
        我们会按照法律法规规定，将境内收集的用户个人信息存储于中国境内。如果您的个人信息存储地点从中国境内转移到境外的，我们将严格依照法律的规定执行。
      </div>
      <div class="wel">
        （2）信息存储的期限
      </div>
      <div class="wel">
        一般而言，我们仅在为实现目的所必需的时间内保留您的个人信息，例如：
      </div>
      <div class="wel">
        手机号码：若您需要使用软件服务，我们需要一直保存您的手机号码，以保证您正常使用该服务。
      </div>
      <div class="wel">
        4、信息注销
      </div>
      <div class="wel">
        您随时可注销此前注册的账户，您可以通过以下方式自行操作在个人中心栏账号设置注销账号。在注销账户之后，我们将停止为您提供产品或服务，并依据您的要求，删除您的注册信息，如你需要删除线下就诊记录等个人信息，可到上海市闵行区七莘路2880号院办进行处理。
      </div>
      <div class="wel">
        5、信息安全
      </div>
      <div class="wel">
        我们努力为用户的信息安全提供保障，以防止信息的丢失、不当使用、末经授权访问或披露。
      </div>
      <div class="wel">
        我们将在合理的安全水平内使用各种安全保护措施以保障信息的安全。例如，我们会使用加密技术（例如，SSL）、匿名化处理等手段来保护您的个人信息。
      </div>
      <div class="wel">
        我们通过不断提升的技术手段加强安装在您设备端的软件的安全能力，以防止您的个人信息泄露。例如，我们为了安全传输会在您设备本地完成部分信息加密的工作；为了预防病毒、木马程序或其他恶意程序、网站，我们可能会了解您设备安装的应用信息、正在运行的进程信息或设备内存中寄存的数据；为了预防诈骗、盗号、仿冒他人等不法行为和进行安全检查可能会分析利用唯一设备标识符、登陆IP地址、操作日志、地理位置信息等数据，以便于采取安全措施或对用户进行安全提醒等。
      </div>
      <div class="wel">
        我们建立专门的管理制度、流程和组织以保障信息的安全。例如，我们严格限制访问信息的人员范围，要求他们遵守保密义务，并进行审计。
      </div>
      <div class="wel">
        若发生个人信息泄露等安全事件，我们会启动应急预案，阻止安全事件扩大，并以推送通知、公告等形式告知您。
      </div>
      <div class="wel">
        6、协议更新
      </div>
      <div class="wel">
        您同意，软件拥有对本协议单方不定时修改的权利。本协议修改，将不会单独征求您的意见并无需取得您的事先同意，所有的修改均以服务平台最新发布的政策版本为准，如您不同意本协议修改的，则您应立即注销您的账号并不再使用；如您继续使用软件的任一功能的，则视为您接受本协议的修改。
      </div>
      <div class="wel">
        7、其他
      </div>
      <div class="wel">
        本协议最终解释权归属软件，您注册使用本客户端即视为您完全接受本协议。请您再次确认已完全理解并自愿接受本协议的全部内容。
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data(){
      return{
      }
    },
    mounted(){
    },
    methods:{
    }
}
</script>
<style scoped lang='less'>
.privacy {
  padding: 40px;
  color: #000;
  .h4 {
    font-size: 40px;
    font-weight: bold;
    line-height: 46px;
    margin-bottom: 40px;
    text-align: center;
  }
  .protect {
    font-size: 32px;
    text-align: justify;
    .h5 {
      font-weight: bold;
      font-size: 36px;
      padding-bottom: 20px;
      padding-top: 20px;
      line-height: 60px !important;
    }
    .wel {
      font-size: 32px;
      line-height: 60px;
      color: #333;
      text-indent: 60px;
      .strong {
        font-weight: bold;
      }
    }
    .bold {
      font-weight: bold;
    }
    .line {
      height: 20px;
      background: #FFFFFF;
      width: 100%;
    }
  }
}
</style>
